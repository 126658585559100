<template>
    <div>
        <LoaderDefault v-if="isLoading" />
        <div v-else>
            <DefaultIndexHeader :routePush="'/registerTaip'" :titleHeader="'Registrar nuevo'" />
            <!-- Table -->
            <a-row :gutter="24" type="flex">
                <a-col :span="24" class="mb-24">
                    <div class="div-table-container">
                        <div class="card-header-table">
                            <div class="bg-gradient-primary">
                                <h5 class="title-table">Transparencia y acceso a la información pública</h5>
                            </div>
                        </div>
                        <table class="table">
                            <thead class="tr-thead">
                                <tr class="tr-columns-data">
                                    <th class="th-colum-item">Nombre</th>
                                </tr>
                                <tr class="tr-columns-actions">
                                    <th class="th-actions">Acciones</th>
                                </tr>
                            </thead>
                            <tbody class="tbody-container">
                                <tr class="tr-body" v-for="(item, index) in this.taips" :key="index">
                                    <div class="tr-columns-data-body">
                                        <td class="th-colum-item">{{ item.title }}</td>
                                    </div>
                                    <br>
                                    <div class="tr-columns-actions">
                                        <td class="td-container-action td-success">
                                            <router-link class="text-white"
                                                :to="{ name: 'detailTaip', params: { _id: item._id } }">
                                                <i class="el-icon-success icon-action"></i>
                                            </router-link>

                                        </td>
                                        <router-link class="text-white"
                                            :to="{ name: 'editTaip', params: { _id: item._id } }">
                                            <td class="td-container-action td-warning">
                                                <i class="el-icon-edit icon-action"></i>
                                            </td>
                                        </router-link>

                                        <td class="td-container-action td-danger" @click="showConfirmation(index)">
                                            <i class="el-icon-delete-solid icon-action"></i>
                                        </td>

                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </a-col>
            </a-row>
            <!-- End Table -->
        </div>
    </div>
</template>

<script>
import LoaderDefault from '../../../components/Loaders/LoaderDefault.vue';
import { db, storage } from '../../../../firebaseConfig';
import DefaultIndexHeader from '../../../components/Headers/DefaultIndexHeader.vue';

export default {
    components: {
        LoaderDefault,
        DefaultIndexHeader
    },

    data() {
        return {
            isLoading: false,
            searchItem: "",
            tableData: [],
            taips: [],
            titleTable: "Transparencia y acceso a la información publica"
        }
    },
    async mounted() {
        this.getTaips()
    },
    methods: {
        searchItemInList() {
            this.searchItem = ""
        },

        async getTaips() {
            this.isLoading = true
            try {
                const collectionRef = db.collection("taips");
                const querySnapshot = await collectionRef.get();
                const listTaip = [];
                querySnapshot.forEach((doc) => {
                    listTaip.push(doc.data());
                });
                this.taips = listTaip;
                this.isLoading = false
            } catch (error) {
                this.isLoading = false
                throw error;
            }
        },

        showConfirmation(doc_id) {
            this.$swal
                .fire({
                    title: 'Eliminar Taip',
                    text: '¿Estás seguro de que deseas realizar esta acción?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#45A825",
                    cancelButtonText: 'Cancelar',
                })
                .then((result) => {
                    if (result.value) this.deleteStorageFolder(doc_id);
                });
        },
        async deleteStorageFolder(childName) {
            this.isLoading = true
            const folderRef = storage.ref().child('pdfsDocumentsTaips/' + childName);
            try {
                const folderFiles = await folderRef.listAll();
                if (folderFiles.items.length === 0) {
                    await db.collection('taips')
                        .doc(this.taips[childName]._id)
                        .delete()
                        .then(() => {
                            this.isLoading = false
                            this.$swal
                                .fire({
                                    title: 'Acción exitosa',
                                    text: 'Taip eliminada exitosamente',
                                    icon: 'success',
                                    showCancelButton: false,
                                    confirmButtonText: 'Aceptar',
                                    confirmButtonColor: "#45A825",
                                })
                            return this.getTaips()
                        })
                } else await Promise.all(folderFiles.items.map((item) => item.delete()));

            } catch (error) {                
                this.isLoading = false
            }
        },
    },

}
</script>