<template>
    <div>
        <header class="container-header-index">
            <div class="h-index-left"></div>
            <div class="h-index-right">

                <router-link class="btn-create-new btn-global btn-text-white" :to="this.routePush">
                    {{ titleHeader }}
                </router-link>
                
                <!-- <div class="h-container-search-input">
                    <input class="h-search-input" placeholder="Buscar…" v-model="filter">
                    <button class="h-btn-seacrh" @click="searchItemInList()">Buscar</button>
                </div> -->

            </div>
        </header>
    </div>
</template>
<script>
export default {
    props: {
        routePush: {
            type: String,
            default: () => "/dashboard",
        },
        titleHeader: {
            type: String,
            default: () => ""
        },
        dataList: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            filter: ""
        }
    },
    methods: {
        searchItemInList() {
            this.filter = ""
        }
    },
}
</script>
